// import 'magnific-popup';
jQuery( document ).ready( function ( $ ) {
	$('.scroll').on('click', function(e) {
		const TARGET = $(this).attr('href');
	
		e.preventDefault();
	
		$('body, html').animate({
			scrollTop: $(TARGET).offset().top - 20
		});
	});

	// Circle animation
	$( '.circle' ).one( 'webkitAnimationEnd oanimationend msAnimationEnd animationend', function ( e ) {
		$( '#generator' ).addClass( 'open' );
	} );

	function scroll_style() {
		var window_top = $(window).scrollTop();
		var div_top = $('#video').offset().top - (window.innerHeight/2);
	 
		if (window_top > div_top){
			$('#video .circle').addClass('grow');
			$('#video').addClass('fade');
		}
	 }
	 $(function() {
	   $(window).scroll(scroll_style);
	   scroll_style();
	  });
} );
